<template>
  <div class="flix-container">
  <div class="flix-row">
  <div class="flix-col-md-12 flix-text-center flix-form-group">
    <h3 class="flix-html-h3">{{ webinars.length }} {{ $tc('message.webinar', webinars.length) }}</h3>
    <flixIcon id="warning-sign" /> BETA-Version
  </div>
  <div class="flix-col-md-12">
    <div v-if="webinars">
      <div v-for="(webinar, index) in webinars" :key="index" class="flix-list-group">
        <div class="flix-list-group-item flix-active"><flixIcon id="webcam" /> {{ webinar[0].title }}</div>
        <div v-for="(w, i) in webinar" :key="i" class="flix-list-group-item flix-text-center">
          <timer :entry="w"/>
          <br>
          <holidayDate :title="webinar[0].title" :date="[$createDate(w.begin), $createDate(w.end)]" />
          <br>
          <webinarStart :webinar="w" :user="'moderator'" :username="$store.getters.user.first_name + ' ' + $store.getters.user.last_name" />
        </div>
      </div>
    </div>
    <div v-else class="flix-text-info">
      <flix-icon id="info-sign"></flix-icon> {{ $t('message.noWebinar') }}
    </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    timer () { return import('@/components/booking/timer') },
    webinarStart () { return import('@/components/webinars/jitsi/start') },
    holidayDate () { return import('@/components/holidays/holidayDate.vue') }
  },
  props: {},
  mounted () {
    this.getWebinars()
  },
  computed: {

  },
  data () {
    return {
      webinars: false
    }
  },
  methods: {
    getWebinars () {
      this.$store.dispatch('getWebinars', { callback: function (ret) { this.webinars = ret }.bind(this) })
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-well
    display: inline-block
    margin-bottom: 0
    margin-right: 10px
</style>
